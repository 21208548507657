<template>
  <div id="baoxiuxinxi">
    <div class="title">维修信息</div>
    <dv-scroll-board
      ref="scrollBoard"
      :config="config"
      style="width: 100%; height: 90%"
    />
  </div>
</template>
<script>
import { DataChart1 } from "@/request/api";
export default {
  data() {
    return {
      config: {},
      time: null,
    };
  },
  mounted() {
    if (!this.time) {
      this.time = setInterval(() => {
        this.DataChart1();
      }, this.$store.state.refreshTime);
    } else {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.DataChart1();
      }, this.$store.state.refreshTime);
    }
    this.DataChart1();
  },
  destroyed() {
    clearInterval(this.time);
  },
  methods: {
    async DataChart1() {
      let res = await DataChart1({ TypeId: 2, CompanyId: this.$store.state.id });

      this.config = {
        rowNum: 5,
        headerHeight: 70,
        waitTime: 4000,
        // columnWidth:[80,220,140,180,100],
        align: ["center", "center", "center", "center","center"],
        ...res.data.theRotaTables,
      };
    },
  },
};
</script>
<style lang="less" scoped>
#baoxiuxinxi {
  width: 100%;
  height: 100%;
  .title {
    font-size: 0.3rem;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(16, 158, 163);
    background: rgba(23, 88, 230, 0.2);
    border-radius: 0.1rem 0.1rem 0 0;
  }
}
</style>