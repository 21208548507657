<template>
  <div id="guzhangdian">
    <div class="top">
      <div class="le">
        <dv-border-box-2 class="baoxiu"
          ><div class="baoxiu1">
            <span>本月报修(次)</span><span>{{ repair }}</span>
          </div></dv-border-box-2
        >
        <dv-border-box-2 class="weixiu"
          ><div class="weixiu1">
            <span>本月完成维修(次)</span><span>{{ repair1 }}</span>
          </div></dv-border-box-2
        >
      </div>
      <dv-border-box-2 class="riBorder"
        ><div class="ri" ref="jindu"></div
      ></dv-border-box-2>
    </div>
    <dv-border-box-2 class="bot">
      <div class="botchart" ref="guzhangdian"></div>
    </dv-border-box-2>
  </div>
</template>
<script>
import { DataChart1,DataChart2 } from "@/request/api";
export default {
  data() {
    return {
      myChart: null,
      myChart1: null,
      repair: 0,
      repair1:0,
      time: null,
    };
  },
  mounted() {
    if (this.time) {
      clearInterval(this.time);
    }
    this.time = setInterval(() => {
      this.DataChart7();
      this.DataChart8();
      this.DataChart12();
      this.DataChart21();
    }, this.$store.state.refreshTime);
    this.jindubiao();
    this.chart();
    this.DataChart7();
    this.DataChart8();
    this.DataChart12();
    this.DataChart21();
  },
  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
    window.removeEventListener("resize", this.screenAdapter1);
    clearInterval(this.time);
  },
  methods: {
    DataChart7() {
      //报修次数
      DataChart1({ TypeId: 5, CompanyId: this.$store.state.id }).then((res) => {
        this.repair = res.data.digitalFlop.number[0];
      });
    },
    DataChart21() {
      //维修次数
      DataChart1({ TypeId: 21, CompanyId: this.$store.state.id }).then((res) => {
        this.repair1 = res.data.digitalFlop.number[0];
      });
    },
    DataChart8() {
      //维修完成率
      let then = this;
      DataChart1({ TypeId: 8, CompanyId: this.$store.state.id }).then((res) => {
        let data = {
          series: [
            {
              data: [
                {
                  // 数据值
                  value: res.data.progressPool.value,
                  // 数据项名称
                  name: "完成率",
                  //该数据项是否被选中
                  selected: false,
                  // 单个扇区的标签配置
                  label: {
                   
                      // 是显示标签
                      show: true,
                      position: "center",
                      fontSize: 20,
                      // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行
                      formatter: "{b}\n{c}%",
                    
                  },
                },
                {
                  value: 100 - res.data.progressPool.value,
                  label: {
                   
                      show: false,
                    
                  },
                  itemStyle: {
                  
                      color: "#fff",
                    },
                 
                },
              ],
            },
          ],
        };
        then.myChart.setOption(data);
      });
    },
    DataChart12() {
      //故障点占比
      DataChart2({ TypeId: 27, CompanyId: this.$store.state.id }).then(
        (res) => {
          let data = {
            series: [
              {
                data: res.data.data,
              },
            ],
          };
          this.myChart1.setOption(data);
        }
      );
    },
    jindubiao() {
      let chartDom = this.$refs.jindu;
      this.myChart = this.$echarts.init(chartDom);
      let option;
      option = {
        // 标题组件，包含主标题和副标题
        // title: {
        //   show: true,
        //   text: "执行任务",
        //   x: "center",
        
        //     fontSize: "15",
        //     color: "green",
        //     fontWeight: "bold",
        //   
        // },
        //  提示框组件
        tooltip: {
          //是否显示提示框组件，包括提示框浮层和 axisPointe
          show: false,
          // 触发类型: item:数据项触发，axis：坐标轴触发
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        // // 图例
        // legend: {
        //     orient: 'vertical',
        //     x: 'left',
        //     data:['完成率']
        // },

        // 系列列表。每个系列通过 type 决定自己的图表类型
        series: [
          {
            // 系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
            name: "任务进度",
            type: "pie",
            // 饼图的半径，数组的第一项是内半径，第二项是外半径
            radius: ["50%", "70%"],
            // 是否启用防止标签重叠策略，默认开启
            avoidLabelOverlap: false,
            // hoverAnimation: false,
            // 标签的视觉引导线样式，在 label 位置 设置为'outside'的时候会显示视觉引导线
            labelLine: {
             
                show: false,
              
            },
            data: [
              {
                // 数据值

                // 数据项名称
                name: "完成率",
                //该数据项是否被选中
                selected: false,
                // 单个扇区的标签配置
                label: {
                 
                    // 是显示标签
                    show: true,
                    position: "center",
                    fontSize: 20,
                    // 标签内容格式器，支持字符串模板和回调函数两种形式，字符串模板与回调函数返回的字符串均支持用 \n 换行
                    formatter: "{b}\n{c}%",
                
                },
              },
              {
                label: {
                
                    show: false,
                  
                },
                itemStyle: {
                
                    color: "#fff",
                  
                },
              },
            ],
          },
        ],
      };

      option && this.myChart.setOption(option);
      window.addEventListener("resize",this.screenAdapter);
    },
    chart() {
      let chartDom = this.$refs.guzhangdian;
      this.myChart1 = this.$echarts.init(chartDom, this.$store.state.theme);
      let option;

      option = {
        backgroundColor: "transparent",
        title: {
          text: "产线异常对比",
          //   subtext: "纯属虚构",
          left: "center",
          top: "10",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
           
          orient: "vertical",
          left: "15",
          top: "40",
        },
        series: [
          {
            left:'80',
            name: "访问来源",
            type: "pie",
            radius: "50%",
           
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              //饼图图形上的文本标签
              
                show: true,
                position: "inner", //标签的位置
                
                  fontWeight: 300,
                  fontSize: 16, //文字的字体大小
                
                formatter: "{d}%",
              
            },
          },
        ],
      };

      option && this.myChart1.setOption(option);
      window.addEventListener("resize",this.screenAdapter1);
    },
    screenAdapter() {
      this.myChart.resize();
    },
    screenAdapter1() {
      this.myChart1.resize();
    },
  },
};
</script>
<style lang="less" scoped>
#guzhangdian {
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 37%;
    display: flex;
    .le {
      width: 50%;
      height: 100%;
      .weixiu,
      .baoxiu {
        height: 50%;
      }
      .baoxiu1,
      .weixiu1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(69, 178, 228);
        font-size: 0.22rem;
      }
    }
    .riBorder {
      width: 50%;
      height: 100%;
      .ri {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bot {
    width: 100%;
    height: 63%;
    .botchart {
      width: 100%;
      height: 100%;
    }
  }
}
</style>