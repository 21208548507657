import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme:'dark',   //light
    id:2,             //公司id
    refreshTime:1000 * 60,   //刷新时间
  },
  mutations: {
    theme(state,data){
      state.theme = data
    },
    setId(state,data){
      state.id = data
    }
  },
  actions: {
  },
  modules: {
  }
})
