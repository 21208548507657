<template>
  <div id="xunjiandian">
    <dv-border-box-2>
      <div class="chart border" ref="xunjiandian"></div
    ></dv-border-box-2>
  </div>
</template>
<script>
import { DataChart3 } from "@/request/api";
export default {
  data() {
    return {
      myChart: null,
      time: null,
    };
  },
  mounted() {
     if(!this.time){
      this.time = setInterval(() => {
      this.DataChart1();
    },this.$store.state.refreshTime);
    }else{
      clearInterval(this.time) 
       this.time = setInterval(() => {
      this.DataChart1();
    }, this.$store.state.refreshTime);
    }
    this.DataChart1();
    this.chart();
  },
  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
    clearInterval(this.time) 
  },
  methods: {
    async DataChart1() {
      let res = await DataChart3({ TypeId: 32, CompanyId:1});
      this.myChart.setOption(res.data);
    },
    chart() {
      var chartDom = this.$refs.xunjiandian;
      this.myChart = this.$echarts.init(chartDom,this.$store.state.theme);
      var option;

      option = {
         title:{
          text:'子公司本月维修保养次数对比',
          padding: [30,0,0,40],
        },
        tooltip: {
          trigger: "item",
        },
        backgroundColor: 'transparent',
        grid:{
            top:80,
            right:35,
            left:50,
            bottom:50
        },
        legend: {
          top:50,
          data: ["子公司本月维修保养次数对比"],
          icon: "circle",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: -20, //倾斜的程度
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ["rgb(225,225,225)"],
            },
          },
          
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
            //   color: "rgb(225,225,225)",
            },
          },
        },

        series: [
          {
            type: "bar",
            name: "子公司本月维修保养次数对比",
            color: "rgb(138,207,87)",
          },
        ],
        label: {
          show: true,
        },
      };
      option && this.myChart.setOption(option);
      window.addEventListener("resize",this.screenAdapter);
    },
    screenAdapter() {
      this.myChart.resize();
    },
  },
};
</script>
<style lang="less">
#xunjiandian {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    height: 15%;
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chart {
    height: 100%;
    // background: chartreuse;
  }
}
</style>
