<template>
  <div id="home">
    <a-row class="tr1" style="height: 12vh">
      <a-col :span="24">
        <dv-decoration-5 style="width: 100%; height: 100%" class="back" />
        <div :class="isEquipment ? 'title' : 'title title1'">
          {{ name }}
          <div class="shijian">{{ date | dateshi }}</div>
        </div>
      </a-col>
    </a-row>
    <a-row class="tr2" style="height: 52.77vh">
      <a-col :span="24" :lg="6" class="td1">
        <feixian />
      </a-col>
      <a-col :span="24" :lg="7" class="td2">
        <guzhangdian />
      </a-col>
      <a-col :span="24" :lg="11" class="td3">
        <baoxiuxinxi />
      </a-col>
    </a-row>
    <a-row class="tr3" style="height: 35.18vh">
      <a-col :span="24" :lg="11" class="td1">
        <bar-chart />
      </a-col>
      <a-col :span="24" :lg="7" class="td2">
        <weixiucishu />
      </a-col>
      <a-col :span="24" :lg="6" class="td3">
        <xunjiandian />
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import guzhangdian from "@/components/guzhangdian";
import baoxiuxinxi from "@/components/baoxiuxinxi";
import barChart from "@/components/barChart";
import weixiucishu from "@/components/weixiucishu";
import xunjiandian from "@/components/xunjiandian";
import feixian from "@/components/feixian";
import { dateshi } from "@/utils";
import { CompaniesList } from "@/request/api";
export default {
  data() {
    return {
      tiem: null,
      tiem1: null,
      date: "",
      name: "金田集团设备管理",
      isEquipment: true, //屏幕大于992是true
    };
  },
  created() {
    // this.$store.commit('setId',this.$route.query.id)
  },
  mounted() {
    this.CompaniesList();
    this.isEquipment = window.innerWidth >= 992;
    if (this.time1) {
      clearInterval(this.time1);
    }
    this.time1 = setInterval(() => {
      this.CompaniesList();
    }, this.$store.state.refreshTime);
    this.date = new Date();
    if (this.time) {
      clearInterval(this.time);
    }
    this.time = setInterval(() => {
      this.date = new Date();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.time);
    clearInterval(this.time1);
  },
  methods: {
    CompaniesList() {
      CompaniesList().then((res) => {
        res.data.forEach((item) => {
          if (item.Id == this.$store.state.id) {
            this.name = item.Name;
          }
        });
      });
    },
  },
  components: {
    guzhangdian,
    baoxiuxinxi,
    barChart,
    xunjiandian,
    weixiucishu,
    feixian,
  },
  filters: {
    dateshi,
  },
};
</script>
<style lang="less" scoped>
.tr1 {
  .back {
    position: absolute;
    left: 0;
    top: 0;
  }
  .ant-col {
    height: 100%;
  }
  .title {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 0.5rem;
    height: 100%;
    color: rgb(35, 137, 233);
    //  background-image:-webkit-linear-gradient(bottom,rgb(184, 236, 236),#62c1ec,rgb(47, 0, 255));
    // -webkit-background-clip:text;
    // -webkit-text-fill-color:transparent;
    .shijian {
      font-size: 0.3rem;
      position: absolute;
      right: 1%;
      top: 50%;
      transform: translateY(-50%);
      //    background-image:-webkit-linear-gradient(bottom,rgb(184, 236, 236),#62c1ec,rgb(47, 0, 255));
      // -webkit-background-clip:text;
      // -webkit-text-fill-color:transparent;
    }
  }
  .title1 {
    font-size: 0.8rem;
    padding-top: 5%;
  }
}
.tr2 {
  .ant-col {
    height: 100%;
  }
}
.tr3 {
  .ant-col {
    height: 100%;
  }
}
</style>