<template>
  <div id="feixian">
    <dv-border-box-2 >
      <div class="title">公司列表</div>
      <div class="list">
        <div :class="item.Id==nameId?'name name1':'name'" v-for="(item,index) in list" :key="index" @click="tap(item.Id)">{{item.Name}}</div>
      </div>
    </dv-border-box-2>
    
  </div>
</template>
<script>
import { CompaniesList } from "@/request/api";
export default {
 data(){
     return {
         list:[],
         nameId:2
     }
 },
 created(){
   
   let num = 1
    // this.$store.commit('setId',this.$route.query.id)
    setInterval(()=>{
      this.$store.commit('setId',this.list[num].Id)
      num++
      if(num==this.list.length){
        num = 0
      }
      this.nameId = this.$store.state.id
    },this.$store.state.refreshTime)
  },
 mounted(){
   this.CompaniesList()
 },
 methods:{
   CompaniesList(){
      CompaniesList().then(res=>{
        this.list = res.data
      })
    
  },
  tap(id){
    //  this.$store.commit('setId',id)
    //  this.nameId = id
  },
 }
};
</script>
<style lang="less" scoped>
#feixian {
  width: 100%;
  height: 100%;
  .title{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .5rem;
    color: rgb(61, 117, 221);
  }
  .list{
    width: 100%;
    height: 85%;
    overflow: auto;
    .name{
      width: 100%;
      height: 14.28%;
      color: #fff;
      font-size: .3rem;
      display: flex;
      // align-items: center;
      justify-content: center;
    }
    .name1{
      color: rgb(47, 12, 145);
    }
  }
}
</style>
