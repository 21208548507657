import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import dataV from '@jiaminghi/data-view'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(dataV)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


document.documentElement.style.fontSize = document.documentElement.clientWidth / 19.2 + 'px'; //设计稿是1920px ，HTML的font-size:100px;
window.onresize = function () {
  let currentWidth = document.documentElement.clientWidth / 19.2;
  document.documentElement.style.fontSize = currentWidth + "px";
  // if (currentWidth < 600) {
  //   document.documentElement.style.fontSize = currentWidth + "px";
  // } else {
  //   document.documentElement.style.fontSize = "600px";
  // }
};