<template>
  <div id="changxian">
    <dv-border-box-2>
      <div class="chart border" ref="changxian"></div>
    </dv-border-box-2>
  </div>
</template>
<script>
import { DataChart2 } from "@/request/api";
export default {
  data() {
    return {
      myChart: null,
      time: null,
    };
  },
  mounted() {
    if (!this.time) {
      this.time = setInterval(() => {
        this.DataChart2();
      }, this.$store.state.refreshTime);
    } else {
      clearInterval(this.time);
      this.time = setInterval(() => {
        this.DataChart2();
      }, this.$store.state.refreshTime);
    }
    this.DataChart2();
    this.chart();
  },
  destroyed() {
    window.removeEventListener("resize", this.screenAdapter);
    clearInterval(this.time);
  },
  methods: {
    async DataChart2() {
      let res = await DataChart2({ TypeId: 31, CompanyId: 1 });
      let data = {
        series: [
          {
            data: res.data.data,
          },
        ],
      };
      this.myChart.setOption(data);
    },
    chart() {
      var chartDom = this.$refs.changxian;
      this.myChart = this.$echarts.init(chartDom, this.$store.state.theme);
      var option;

      option = {
          backgroundColor: "transparent",
        title: {
          text: "子公司本月巡检点异常占比",
        //   subtext: "纯属虚构",
          left: "center",
          top:'10'
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "15",
          top:'40'
        },
        series: [
          {
            left:'150',
            name: "访问来源",
            type: "pie",
            radius: "50%",
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              //饼图图形上的文本标签
              
                show: true,
                position: "inner", //标签的位置
               
                  fontWeight: 300,
                  fontSize: 16, //文字的字体大小
                
                formatter: "{d}%",
              
            },
          },
        ],
      };
      option && this.myChart.setOption(option);
      window.addEventListener("resize", this.screenAdapter);
    },
    screenAdapter() {
      this.myChart.resize();
    },
  },
};
</script>
<style lang="less">
#changxian {
  width: 100%;
  height: 100%;
  .title {
    height: 15%;
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chart {
    height: 100%;
  }
}
</style>
